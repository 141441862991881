import React from 'react';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Column, FormGroup, Row, SpacedGroup } from '@dabapps/roe';
import { DefaultRootState, useSelector } from 'react-redux';

import { ResponseErrors } from '^/common/error-handling/types';
import { MusicSubTypesData, SetMusicalPreferencesData } from './types';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import {
  FAVOURITE_MUSIC_SUB_TYPES_KEY,
  FAVOURITE_MUSIC_TYPES_KEY,
} from './actions';

interface OtherInputFieldProps {
  name: string;
  placeholder: string;
  disabled?: boolean;
  errors?: ResponseErrors;
}

const OtherInputField: React.FunctionComponent<OtherInputFieldProps> = props => (
  <Row>
    <Column xs={12}>
      <FormGroup block>
        <Field
          id={props.name}
          name={props.name}
          type="text"
          component="input"
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
        <FormErrorRenderer
          formErrors={props.errors}
          formErrorsKey={props.name}
        />
      </FormGroup>
    </Column>
  </Row>
);

interface TextInputFieldProps {
  name: string;
  placeholder: string;
  disabled?: boolean;
  errors?: ResponseErrors;
}

const TextInputField: React.FunctionComponent<TextInputFieldProps> = props => (
  <Row>
    <Column xs={12}>
      <FormGroup block>
        <Field
          id={props.name}
          component="input"
          name={props.name}
          type="text"
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
        <FormErrorRenderer
          formErrors={props.errors}
          formErrorsKey={props.name}
        />
      </FormGroup>
    </Column>
  </Row>
);

interface CheckboxInputProps {
  name: string;
  labelText: string;
  disabled?: boolean;
}

const CheckboxInput: React.FunctionComponent<CheckboxInputProps> = props => (
  <SpacedGroup block>
    <Field
      id={props.name}
      type="checkbox"
      component="input"
      name={props.name}
      disabled={props.disabled}
    />
    <label htmlFor={props.name}>{props.labelText}</label>
  </SpacedGroup>
);

interface ExternalProps {
  isLoading?: boolean;
  errors?: ResponseErrors;
  readOnly?: boolean;
  musicTypes: Record<string, string>;
  musicSubTypes: MusicSubTypesData;
}

type OwnProps = InjectedFormProps<SetMusicalPreferencesData, ExternalProps>;

type Props = OwnProps & ExternalProps;

const MusicalPreferencesForm: React.FunctionComponent<Props> = props => {
  const formValues = useSelector<DefaultRootState, SetMusicalPreferencesData>(
    state =>
      getFormValues('MUSICAL_PREFERENCES_FORM')(
        state
      ) as SetMusicalPreferencesData
  );

  return (
    // eslint-disable-next-line react/jsx-handler-names
    <form onSubmit={props.handleSubmit} className="admin-edit-create-form">
      <FormGroup block className="bold-label">
        <label htmlFor={FAVOURITE_MUSIC_TYPES_KEY}>
          What were some of your or your loved one’s favourite types of music?
          (select more than one if applicable)
        </label>
        <div className="display-block">
          {Object.entries(props.musicTypes).map(
            ([musicType, musicDisplayName]) => (
              <CheckboxInput
                key={`row_genre_${musicType}`}
                name={`${FAVOURITE_MUSIC_TYPES_KEY}_${musicType}`}
                labelText={musicDisplayName}
                disabled={props.isLoading || props.readOnly}
              />
            )
          )}
          {formValues && formValues.favourite_music_type_other && (
            <OtherInputField
              name="favourite_music_types_other_freetext"
              placeholder="other favourite type of music"
              disabled={props.isLoading || props.readOnly}
              errors={props.errors}
            />
          )}
          {formValues && formValues.favourite_music_type_classical && (
            <FormGroup block className="bold-label">
              <label>
                Which of the following categories of classical music did you or
                your loved one enjoy listening to? (select more than one if
                applicable)
              </label>
              <SpacedGroup block>
                {Object.entries(props.musicSubTypes.classical).map(
                  ([musicSubType, musicDisplayName]) => (
                    <CheckboxInput
                      key={`row_sub_genre_${musicSubType}`}
                      name={`${FAVOURITE_MUSIC_SUB_TYPES_KEY}_classical_${musicSubType}`}
                      labelText={musicDisplayName}
                      disabled={props.isLoading || props.readOnly}
                    />
                  )
                )}
              </SpacedGroup>
            </FormGroup>
          )}
          {formValues &&
            formValues.favourite_music_type_classical &&
            formValues.music_sub_type_classical_other && (
              <OtherInputField
                name={`${FAVOURITE_MUSIC_SUB_TYPES_KEY}_other_freetext_classical`}
                placeholder="other classical music type"
                disabled={props.isLoading || props.readOnly}
                errors={props.errors}
              />
            )}
        </div>
        <FormErrorRenderer
          formErrors={props.errors}
          formErrorsKey={`${FAVOURITE_MUSIC_TYPES_KEY}s`}
        />
      </FormGroup>

      <FormGroup block className="bold-label">
        <label>
          Include songs from the country/countries you or your loved one lived
          in during their teenage years
        </label>
        <p>
          If you select this, we will incude songs from the country/countries
          you or your loved one grew up in, if that is different from the UK.
        </p>
        <CheckboxInput
          key="include_songs_from_countries_of_residence"
          name="include_songs_from_countries_of_residence"
          labelText="Yes please"
          disabled={props.isLoading || props.readOnly}
        />
      </FormGroup>

      <FormGroup block className="bold-label">
        <label>Favourite artists</label>
        <p>
          If possible, list any personal favourite artists or songs from yours
          or your loved one’s youth. Please ensure you spell the names
          correctly, so that we can find exactly what you are looking for. If
          you are unsure of the spelling, we suggest looking it up on{' '}
          <a href="https://www.google.com/" target="_blank">
            Google
          </a>
          .{' '}
          <i>
            As this is a very early version of our product, we are working with
            limited song catalogue. Some of the names you write down may not end
            up in your final playlist but we will work on including them in
            future versions of this App. We will provide you with instructions
            on how to add specific songs to your playlist once it’s been
            created.
          </i>
        </p>
        <TextInputField
          name="artist_1"
          placeholder="artist"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="artist_2"
          placeholder="artist"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="artist_3"
          placeholder="artist"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="artist_4"
          placeholder="artist"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="artist_5"
          placeholder="artist"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
      </FormGroup>

      <FormGroup block className="bold-label">
        <label>Favourite songs</label>
        <TextInputField
          name="song_1"
          placeholder="song"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="song_2"
          placeholder="song"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="song_3"
          placeholder="song"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="song_4"
          placeholder="song"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
        <TextInputField
          name="song_5"
          placeholder="song"
          disabled={props.isLoading || props.readOnly}
          errors={props.errors}
        />
      </FormGroup>

      {!props.readOnly && (
        <Button className="secondary margin-vertical-base" type="submit">
          Next...
        </Button>
      )}
    </form>
  );
};

export default reduxForm<SetMusicalPreferencesData, ExternalProps>({
  form: 'MUSICAL_PREFERENCES_FORM',
})(MusicalPreferencesForm);
