import React from 'react';
import { ResolveThunks, connect } from 'react-redux';

import { updatePlaylistName } from './actions';

interface ExternalProps {
  playlistName: string;
  sessionId: string;
}

type DispatchProps = ResolveThunks<{
  updatePlaylistName: typeof updatePlaylistName;
}>;

type Props = ExternalProps & DispatchProps;

interface State {
  playlistName: string;
  hasNameChanged: boolean;
}

class PlaylistName extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      playlistName: props.playlistName,
      hasNameChanged: false,
    };
  }

  private onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    this.setState({ playlistName: newValue, hasNameChanged: true });
  };

  private onSave = () => {
    this.props.updatePlaylistName(
      this.props.sessionId,
      this.state.playlistName
    );
    this.setState({ hasNameChanged: false });
  };

  public render() {
    return (
      <div className="display-none">
        <p className="bold font-size-h3 margin-bottom-small">Playlist name</p>
        <input
          type="text"
          value={this.state.playlistName}
          onChange={this.onHandleChange}
        />
        {this.state.hasNameChanged && (
          <button
            className="primary main-site-button margin-left-large"
            onClick={this.onSave}
          >
            <b>Save</b>
          </button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  updatePlaylistName,
};

export default connect(undefined, mapDispatchToProps)(PlaylistName);
