import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Column, Container, Row } from '@dabapps/roe';
import { faAngleDoubleRight } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppButton from '^/common/components/app-button';

type RouteProps = RouteComponentProps<{
  sessionId: string;
}>;

type Props = RouteProps;

export class YoutubePlaylistCreated extends React.PureComponent<Props> {
  public render() {
    return (
      <div className="thank-you-pages">
        <Row className="orange">
          <h1 className="font-family-headers">
            Thanks - we've emailed you a link to your playlist!
          </h1>
        </Row>
        <Container>
          <Row className="top-padding">
            <Column md={12}>
              <p>
                We've created a playlist on your <strong>YouTube</strong>{' '}
                account!
              </p>
              <p>
                A link to the playlist has been sent to the email address you
                gave us, with instructions on how to access it (and how to
                create a free YouTube account if you need one).
              </p>
            </Column>
          </Row>
          <Row className="top-padding">
            <Column md={9}>
              <h2>Your feedback could win you a £100 Amazon voucher!</h2>
              <p>
                Music Effects feedback on your experience using the Playlist
                Maker is vital to us. As a thank you for completing the feedback
                survey we will send you in the coming weeks, your details will
                be added to a draw to win a <strong>£100 Amazon voucher</strong>
                ! Ts and Cs apply*.
              </p>
              <p>
                To make completing the survey easier, please try to keep a note
                of any observations you notice about responses to the music. How
                often is the music played? Is the music helpful at difficult
                care times, for instance?
              </p>
              <p className="larger">
                <strong>Happy listening!</strong>
              </p>
            </Column>
            <Column md={3} className="voucher">
              <img src="/static/images/amazon-voucher.png" width="200px" />
            </Column>
          </Row>
        </Container>

        <Row className="button-row">
          <Column md={4} className="text-align-center padding-bottom-large">
            <p className="bold font-size-large">Support our work</p>
            <a
              href="//widgets.justgiving.com/Button/Redirect?p=eyJJZCI6IjNjNTAzMGRiLTY0OGEtNDY2YS1hZTMyLTBhYzYwMjMyZGVlZSIsIkNoYXJpdHlJZCI6Mjc1NDA2OCwiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJKR3dlYnNpdCIsIlR5cGUiOiJEb25hdGUifQ=="
              target="_blank"
            >
              <AppButton className="button-call-to-action">
                <span className="button-text">Donate now </span>
                <FontAwesomeIcon
                  size="sm"
                  icon={faAngleDoubleRight as IconProp}
                />
              </AppButton>
            </a>
          </Column>
          <Column md={4} className="text-align-center padding-bottom-large">
            <p className="bold font-size-large">Join our community</p>
            <a
              href="https://www.facebook.com/groups/209835070885078"
              target="_blank"
            >
              <AppButton className="button-call-to-action">
                <span className="button-text">Join today </span>
                <FontAwesomeIcon
                  size="sm"
                  icon={faAngleDoubleRight as IconProp}
                />
              </AppButton>
            </a>
          </Column>
          <Column md={4} className="text-align-center padding-bottom-large">
            <p className="bold font-size-large">Share Playlist Maker</p>
            <a href="/" className="text-decoration-none" target="_blank">
              <AppButton className="button-call-to-action">
                <span className="button-text">Playlist Maker </span>
                <FontAwesomeIcon
                  size="sm"
                  icon={faAngleDoubleRight as IconProp}
                />
              </AppButton>
            </a>
          </Column>
        </Row>
        <Container>
          <Row className="top-padding">
            <p className="t-and-cs">
              <strong>* Terms and Conditions for vouchers prize draw</strong>
              <br />
              1. One entry per person creating a MFMM playlist using the
              Playlist Maker and completing the Music Effects feedback survey we
              will email you. 2. Entry is automatic once survey is completed. If
              you do not wish to be included in the draw, please contact us at
              info@musicformymind.com. 3. 2024 draw takes place on 31st December
              2024. 4. Prize is 1 x £100 Amazon voucher. 5. Voucher can be sent
              electronically or by post. 6. The prize is non-exchangeable,
              non-transferable and no cash alternatives will be offered. 7. You
              can create multiple playlists for multiple persons, but only one
              entry to the draw per person per year will be given. 8. Email
              info@musicformymind.com for help or winner details after draw
              dates. 9. Entrants must be over 18 and be able to provide proof of
              UK residency. 10. Prize draw is free to enter. 11. By entering the
              prize draw you are agreeing to these prize draw terms and
              conditions. 12. The winner will be notified after 31st December
              2024 via the email provided. 13. MFMM will attempt to contact the
              winner by email up to two times. 14. If the winner does not
              respond to the emails notifying them of their win within 14 days
              of the second email, they will lose their right to the prize, and
              MFMM reserves the right to choose and notify a new winner. 15. You
              consent to any personal information you provide in entering the
              prize draw being used by MFMM for the purposes of administering
              the prize draw, and for those purposes as defined within our
              privacy notice. 16. All personal information shall be used in
              accordance with{' '}
              <a href="https://musicformymind.com/privacy-policy-2/">
                MFMM’s Privacy Policy
              </a>
              . 17. MFMM does not accept any liability for any damage, loss,
              injury or disappointment suffered by any entrants as a result of
              either participating in the prize draw or being selected for a
              prize. 18. MFMM reserves the right to cancel the prize draw or
              amend these terms and conditions at any time, without prior
              notice. 19. The prize draw and these terms and conditions will be
              governed by English law and any disputes will be subject to the
              exclusive jurisdiction of the courts of England and Wales.
            </p>
          </Row>
        </Container>
      </div>
    );
  }
}

export default YoutubePlaylistCreated;
