import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Column, Row } from '@dabapps/roe';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

import YoutubeChannelPlaylistTile from './results/youtube-channel-playlist-tile';

type RouteProps = RouteComponentProps<{
  sessionId: string;
}>;

type Props = RouteProps;

export class NoPlaylistCreated extends React.PureComponent<Props> {
  public render() {
    return (
      <Container>
        <div className="user-page-title">
          <h2 className="font-color-base">
            You are almost there! Complete this final step:
          </h2>
        </div>
        <p>
          The final step before getting your playlist in your YouTube account is
          to create a YouTube channel. It's free to create and only takes 3
          clicks! Go to{' '}
          <a target="blank" href="https://www.youtube.com/">
            YouTube
          </a>{' '}
          and sign in to your YouTube account (this will be using your Google
          account sign in details). In the top right corner of the screen, click
          on your profile icon and then click "Create a channel".
        </p>
        <Row>
          <Column md={12} className="text-align-center">
            <img
              src="/static/images/create-channel-one-opt-v2.jpg"
              alt="Location of Profile Icon and Create a channel button"
            />
          </Column>
        </Row>
        <p>
          In the box that comes up, check your name details and click on "Create
          Channel" (you don't need to upload a picture, this is only optional).
        </p>
        <Row>
          <Column md={12} className="text-align-center">
            <img
              src="/static/images/create-channel-two-opt-v2.jpg"
              alt="Creating a channel window"
            />
          </Column>
        </Row>
        <p>
          You now have a YouTube channel! Click the button below to add your
          Music for my Mind playlist to it and start listening! If you wish to
          go back to the previous page please click{' '}
          <a href={`/session/${this.props.match.params.sessionId}/`}>here</a>.
          If you are having issues creating a YouTube channel or accessing your
          playlist, please email us at{' '}
          <a href="mailto: info@musicformymind.com?subject=YouTube Playlist Help">
            info@musicformymind.com
          </a>{' '}
          and our team will gladly help you.
        </p>
        <Row>
          <Column md={4} />
          <Column md={4}>
            <YoutubeChannelPlaylistTile
              titleName="YouTube"
              descriptionName="YouTube"
              icon={{
                faIcon: faYoutube,
                classNames: 'youtube-icon',
              }}
              link={`/api/playlists/youtube/initiate-authorization/?session=${this.props.match.params.sessionId}`}
            />
          </Column>
          <Column md={4} />
        </Row>
      </Container>
    );
  }
}

export default NoPlaylistCreated;
