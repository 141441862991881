import { Column, Section, Row } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/fontawesome-free-solid';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import AppButton from '^/common/components/app-button';

const LandingPage: React.FunctionComponent = React.memo(() => (
  <div className="landing-page">
    <div className="logos">
      <img src="/static/images/MFMM-Primary-Logo.svg" width="250px" />
      <a href="https://musicformymind.com/" target="_blank" className="white">
        <img src="/static/images/world-logo.png" width="250px" />
      </a>
    </div>
    <Section className="margin-horizontal-none">
      <Row>
        <Column md={7} lg={7}>
          <h1 className="bold heading-one">Welcome to the</h1>
          <h1 className="heading-two">Playlist Maker</h1>
          <p className="line-height-larger margin-top-none padding-bottom-large white">
            Personalised music playlists can help people living with dementia or
            memory loss to reconnect with loved ones and carers and reduce
            anxiety and depression.
          </p>
          <p className="line-height-larger padding-bottom-large white">
            Create and listen to a FREE playlist with our
            <strong> Playlist Maker</strong> for someone with dementia or memory
            loss today.
          </p>
          <div className="steps">
            <img src="/static/images/step1.png" width="230px" />
            <img src="/static/images/step2.png" width="230px" />
            <img src="/static/images/step3.png" width="230px" />
          </div>
        </Column>

        <Column md={5} lg={5}>
          <div className="start-button">
            <Link to="/create-session/" className="text-decoration-none">
              <AppButton className="button-call-to-action">
                <span className="button-text">Start questionnaire </span>
                <FontAwesomeIcon
                  size="sm"
                  icon={faAngleDoubleRight as IconProp}
                />
              </AppButton>
            </Link>
          </div>
        </Column>
      </Row>
      <Row>
        <Column md={7} lg={7}>
          {' '}
        </Column>
      </Row>
    </Section>
  </div>
));

export default LandingPage;
