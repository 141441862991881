import { getItemByName, GET_ITEM } from '@dabapps/redux-api-collections';
import { isPending } from '@dabapps/redux-requests';
import { Container, Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { faAngleDoubleRight } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { collections } from '^/common/collections';
import { PlaylistData } from '^/common/types';
import { StoreState } from '^/store/types';
import Loading from '^/common/components/loading';
import { updatePlaylistAndReloadPage } from '^/app/unauthenticated/user/questionnaire/results/actions';
import ResultsScreenSong from '^/app/unauthenticated/user/questionnaire/results/results-screen-song';
import MusicPlayer from '^/common/components/music-player';
import MusicServiceTile from './music-service-tile';
import { FEATURE_FLAGS } from '^/utils/template';
import PlaylistName from './playlist-name';
import AppButton from '^/common/components/app-button';

const {
  actions: { getItem },
} = collections;

type DispatchProps = ResolveThunks<{
  getItem: typeof getItem;
  updatePlaylistAndReloadPage: typeof updatePlaylistAndReloadPage;
}>;

interface OwnProps {
  sessionId: string;
}

type StateProps = {
  playlistData: PlaylistData | undefined;
  isLoading: boolean;
};

type Props = DispatchProps & OwnProps & StateProps;

export class Results extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getItem('sessions/get-playlist', this.props.sessionId);
  }

  public render() {
    const { playlistData, isLoading } = this.props;
    return (
      <>
        <div className="results-page">
          <Container>
            <div className="thank-you">
              <h2 className="font-family-headers">
                Thank you. Scroll down for your personalised playlist!
              </h2>
            </div>
            <div>
              <p className="header">
                Please choose how you would like to listen.
              </p>
              <p className="text">
                Once you have selected from the below, a link to your playlist
                will be emailed to you to listen on your chosen streaming
                platform. MFMM does not receive listener data from Spotify or
                YouTube.
              </p>
            </div>
          </Container>
          {isLoading && (
            <div className="loading">
              <Loading />
            </div>
          )}
          {!isLoading && !playlistData && (
            <div className="loading">
              <Loading />
            </div>
          )}
          {!isLoading && playlistData && !playlistData.playlist.length && (
            <div className="loading">
              <p>No songs matched the details you entered.</p>
            </div>
          )}
          {!isLoading && playlistData && !!playlistData.playlist.length && (
            <>
              <div>
                <Container>
                  <Row>
                    <Column md={12} className="blue-box">
                      <Column md={6}>
                        <MusicServiceTile
                          titleName="Spotify"
                          descriptionName="Spotify"
                          image={{
                            imageSrc: '/static/images/listen-spotify.png',
                            classNames: 'youtube-image',
                          }}
                          link={`/api/playlists/spotify/initiate-authorization/?session=${this.props.sessionId}`}
                        />
                      </Column>
                      {FEATURE_FLAGS['hide_youtube'] !== 'True' && (
                        <Column md={6}>
                          <MusicServiceTile
                            titleName="YouTube"
                            descriptionName="YouTube"
                            image={{
                              imageSrc: '/static/images/listen-youtube.png',
                              classNames: 'youtube-image',
                            }}
                            link={`/api/playlists/youtube/initiate-authorization/?session=${this.props.sessionId}`}
                          />
                        </Column>
                      )}
                    </Column>
                  </Row>
                </Container>
              </div>
              <div className="playlist-text">
                <Container>
                  <Row>
                    <Column md={12}>
                      <h1>
                        <img src="/static/images/arrow.png" /> My Music for my
                        Mind playlist
                      </h1>
                      <p>
                        We really hope you or your loved one enjoy the music in
                        this playlist. And remember, this is the starting point
                        for a journey to personalising this music collection!
                        You can easily adapt the playlist on YouTube or Spotify
                        by removing or adding tracks that evoke particular
                        memories or seem to provide calm at difficult times of
                        day.
                      </p>
                      <p>
                        <b>
                          Don't have a Spotify or YouTube account, or looking to
                          edit your playlist?
                        </b>{' '}
                        Visit our{' '}
                        <a
                          href="https://musicformymind.com/how-to-guides-youtube-spotify/"
                          target="_blank"
                        >
                          How-To Guides
                        </a>{' '}
                        for help.
                      </p>
                      <p>
                        <b>Happy listening!</b>
                      </p>
                    </Column>
                  </Row>
                  <Column md={5} className="player">
                    <PlaylistName
                      playlistName={playlistData.playlist_name}
                      sessionId={this.props.sessionId}
                    />
                    <MusicPlayer playlist={playlistData.playlist} />
                  </Column>
                  <Column md={7} className="player">
                    <div className="help-text">
                      <p>
                        Play 30 second snippets of your songs or remove any
                        you'd prefer to
                      </p>
                    </div>
                  </Column>
                </Container>
              </div>
              <div className="playlist">
                <Container>
                  <ul className="results-screen-song-listing padding-left-none font-size-large">
                    {playlistData.playlist.map((song, index) => (
                      <ResultsScreenSong
                        key={index}
                        song={song}
                        songNumber={index}
                        removeSong={this.removeSong}
                      />
                    ))}
                  </ul>
                </Container>
              </div>
              <div className="contact-us">
                <Container>
                  <div className="instructions-wrapper">
                    <div className="row">
                      <Column md={6}>
                        <p className="instruction-text">
                          Having problems getting set up or have a question
                          about your playlist?
                        </p>
                      </Column>
                      <Column md={6}>
                        <div>
                          <a
                            href="mailto:info@musicformymind.com"
                            target="_blank"
                            className="text-decoration-none"
                          >
                            <AppButton className="button-call-to-action">
                              <span className="button-text">Contact us </span>
                              <FontAwesomeIcon
                                size="sm"
                                icon={faAngleDoubleRight as IconProp}
                              />
                            </AppButton>
                          </a>
                        </div>
                      </Column>
                    </div>
                  </div>
                </Container>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  public removeSong = (song: string) => {
    this.props.updatePlaylistAndReloadPage(this.props.sessionId, song);
    setTimeout(
      () => this.props.getItem('sessions/get-playlist', this.props.sessionId),
      500
    );
  };
}

const mapStateToProps = ({ responses, items }: StoreState): StateProps => {
  return {
    playlistData: getItemByName(items, 'sessions/get-playlist') as
      | PlaylistData
      | undefined,
    isLoading: isPending(responses, GET_ITEM, 'sessions/get-playlist'),
  };
};

export default connect(mapStateToProps, {
  getItem,
  updatePlaylistAndReloadPage,
})(Results);
