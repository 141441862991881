import { AxiosResponse } from 'axios';
import React from 'react';
import { Column, SpacedGroup, Button } from '@dabapps/roe';
import { connect, ResolveThunks } from 'react-redux';
import { isPending, hasFailed } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';

import {
  setMusicalPreferences,
  SET_MUSICAL_PREFERENCES,
  FAVOURITE_MUSIC_TYPES_KEY,
  FAVOURITE_MUSIC_SUB_TYPES_KEY,
} from './actions';
import { StoreState } from '^/store/types';
import {
  SetMusicalPreferencesData,
  MusicalPreferencesData,
  MusicSubTypesData,
} from './types';
import { getResponseErrors } from '^/common/error-handling/utils';
import { selectMusicSubTypes, selectMusicTypes } from './selectors';
import { changeCurrentWizardPage } from '^/common/components/questionnaire/wizard/actions';
import { RESEARCH_QUESTIONNAIRE_ID } from '^/app/authenticated/questionnaire/const';
import { ResearchQuestionnairePageName } from '^/app/authenticated/questionnaire/types';
import MusicalPreferencesForm from './form';
import { ResponseErrors } from '^/common/error-handling/types';

interface OwnProps {
  sessionId: string;
  readonly?: boolean;
  isResearchQuestionnaire: boolean;
  initialData?: MusicalPreferencesData;
}

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: ResponseErrors;
  musicTypes: Record<string, string>;
  musicSubTypes: MusicSubTypesData;
}

type DispatchProps = ResolveThunks<{
  setMusicalPreferences: typeof setMusicalPreferences;
  changeCurrentWizardPage: typeof changeCurrentWizardPage;
  push: typeof push;
}>;

type Props = DispatchProps & StateProps & OwnProps;

export const transformInitialData = (
  data: MusicalPreferencesData | undefined
): SetMusicalPreferencesData | undefined => {
  if (data) {
    const favouriteMusicTypes: Record<string, boolean> = {};
    if (data.favourite_music_types) {
      data.favourite_music_types.map(musicType => {
        favouriteMusicTypes[`${FAVOURITE_MUSIC_TYPES_KEY}_${musicType}`] = true;
      });
    }

    const favouriteMusicSubTypes: Record<string, boolean> = {};
    if (data.favourite_music_sub_genres) {
      data.favourite_music_sub_genres.map(musicSubType => {
        favouriteMusicSubTypes[
          `${FAVOURITE_MUSIC_SUB_TYPES_KEY}_classical_${musicSubType}`
        ] = true;
      });
    }

    const itemData: SetMusicalPreferencesData = {
      ...data,
      ...data.favourite_artists_and_songs,
      ...favouriteMusicTypes,
      ...favouriteMusicSubTypes,
    };
    itemData['music_sub_type_other_freetext_classical'] =
      data.favourite_music_types_classical_other_freetext;

    return itemData;
  }
};

export class MusicalPreferences extends React.PureComponent<Props> {
  private updateMusicalPreferences = (data: SetMusicalPreferencesData) => {
    const initialData = transformInitialData(this.props.initialData);
    const newData = {
      ...initialData,
      ...data,
    };
    if (initialData) {
      this.props
        .setMusicalPreferences(
          this.props.sessionId,
          newData,
          this.props.isResearchQuestionnaire
        )
        .then((response: void | AxiosResponse) => {
          if (response) {
            this.props.changeCurrentWizardPage(
              RESEARCH_QUESTIONNAIRE_ID,
              ResearchQuestionnairePageName.FEEDBACK
            );
          }
        });
    } else {
      this.props.setMusicalPreferences(
        this.props.sessionId,
        newData,
        this.props.isResearchQuestionnaire
      );
    }
  };

  private onClickCancel = () => {
    if (this.props.isResearchQuestionnaire) {
      return this.props.changeCurrentWizardPage(
        RESEARCH_QUESTIONNAIRE_ID,
        ResearchQuestionnairePageName.PERSONALITY_AND_BEHAVIOUR
      );
    }

    return this.props.push('/');
  };

  private onClickNext = () => {
    this.props.changeCurrentWizardPage(
      RESEARCH_QUESTIONNAIRE_ID,
      ResearchQuestionnairePageName.FEEDBACK
    );
  };

  private onFormSubmit = (formData: SetMusicalPreferencesData) => {
    this.updateMusicalPreferences(formData);
  };

  public render() {
    return (
      <div>
        <h3>Musical Preferences (page 3 of 4)</h3>
        <hr />
        <Column md={8}>
          <MusicalPreferencesForm
            onSubmit={this.onFormSubmit}
            isLoading={this.props.isLoading}
            errors={this.props.errors}
            initialValues={transformInitialData(this.props.initialData)}
            readOnly={this.props.readonly}
            musicTypes={this.props.musicTypes}
            musicSubTypes={this.props.musicSubTypes}
          />

          <SpacedGroup>
            {this.props.isResearchQuestionnaire && this.props.readonly && (
              <Button className="primary" onClick={this.onClickNext}>
                Next Page
              </Button>
            )}
            <a
              className="margin-left-large text-decoration-none uppercase font-weight-normal link-grey"
              onClick={this.onClickCancel}
            >
              {this.props.isResearchQuestionnaire ? 'Previous Page' : 'Cancel'}
            </a>
          </SpacedGroup>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    isLoading: isPending(state.responses, SET_MUSICAL_PREFERENCES),
    hasFailed: hasFailed(state.responses, SET_MUSICAL_PREFERENCES),
    errors: getResponseErrors(state.responses, SET_MUSICAL_PREFERENCES),
    musicTypes: selectMusicTypes(state),
    musicSubTypes: selectMusicSubTypes(state),
  };
}

export default connect(mapStateToProps, {
  setMusicalPreferences,
  changeCurrentWizardPage: changeCurrentWizardPage,
  push,
})(MusicalPreferences);
