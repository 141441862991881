import React from 'react';
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';
import { AdminEditCreate, FormErrors } from '@dabapps/chadmin';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { isPending, getErrorData } from '@dabapps/redux-requests';

import { noop } from '^/common/utils';
import { ThunkDispatch, StoreState } from '^/store/types';
import {
  updatePublicSessionPersonalDetailsAndShowToast,
  UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS,
} from './actions';
import config from './config';
import { DESKTOP_WIDTH, FORM_NAME } from './constants';
import { PersonalDetailsData } from './types';
import { getChadminErrorsFromAxiosError } from '^/common/error-handling/utils';

interface DispatchProps {
  onUpdate(data: PersonalDetailsData): void;
}

export type RouteProps = RouteComponentProps<{
  sessionId: string;
}>;

interface StateProps {
  isLoading: boolean;
  errors: FormErrors | undefined;
}

type Props = RouteProps & DispatchProps & StateProps;

export const ContactDetails = (props: Props) => {
  const { onUpdate, isLoading, errors } = props;

  return (
    <div>
      <Row>
        <Column xs={12} md={DESKTOP_WIDTH}>
          <p className="font-weight-bold">
            Please provide your name, email address and phone number so we can
            send you the link to the playlist and communicate further with any
            follow-up or feedback.
          </p>
        </Column>
      </Row>
      <AdminEditCreate
        formName={FORM_NAME}
        fields={Object.keys(config)}
        itemOptions={config}
        setPendingUploadInForm={noop}
        saveButtonText="Next..."
        loading={isLoading}
        createItem={onUpdate}
        className="font-weight-bold"
        saveButtonClassName="button secondary margin-vertical-base"
        itemErrors={errors}
        additionalFormControls={
          <a
            className="margin-left-large text-decoration-none uppercase font-weight-normal link-grey"
            href="/"
          >
            Cancel
          </a>
        }
      >
        <Column>
          <hr className="border-base" />
        </Column>
      </AdminEditCreate>
    </div>
  );
};

function mapStateToProps(state: StoreState): StateProps {
  return {
    isLoading: isPending(
      state.responses,
      UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS
    ),
    errors: getChadminErrorsFromAxiosError(
      getErrorData(state.responses, UPDATE_PUBLIC_SESSION_PERSONAL_DETAILS)
    ),
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch,
  props: RouteProps
): DispatchProps {
  return {
    onUpdate: (data: PersonalDetailsData) =>
      dispatch(
        updatePublicSessionPersonalDetailsAndShowToast(
          props.match.params.sessionId,
          data
        )
      ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactDetails)
);
